/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {
  configAddLanguage: any;
  configGetLanguages(configGetLanguages: any) {
    throw new Error('Method not implemented.');
  }

  // Staging
 // baseUrl = 'https://itcdigitalcouponapi.bigcityvoucher.co.in/v1/';

  //prod

   baseUrl = 'https://appapi.digitalcoupon.bigcitydays.in/v1/';



  getUserData = this.baseUrl + "appuser/get-retailer?transaction_ref=";
  postUserData = this.baseUrl + "appuser/register-retailer";









  // gameDetail = this.baseUrl + "reports-panel/gamedetails";
  // leaderBoard = this.baseUrl + "reports-panel/leaderboard";
  // leaderBoardRefrence = this.baseUrl + "reports-panel/leaderboardreference";
  // registered = this.baseUrl + "reports-panel/registered";
  // participents = this.baseUrl + "reports-panel/allparticipants";
  // teamsPlayed = this.baseUrl + "reports-panel/playerplayedcount";
  // coupanDetail = this.baseUrl + "reports-panel/coupondetails";
  // voucherDetail = this.baseUrl + "reports-panel/voucherdetails";


  // // Skus
  // addSkus = this.baseUrl + "skus/add-skus";
  // getBrands = this.baseUrl + "skus/list-skus-master?program_id=&brand_code=&brand_name=";
  // editSkus = this.baseUrl + "skus/edit-skus";

  // // PackConfig
  // getProgramList = this.baseUrl + "skus/list-program?name=";
  // getPack = this.baseUrl + "skus/list-pack-config?program_id=&sku_id";
  // getGeneratedCoupon = this.baseUrl + "skus/list-coupon-request";
  // addPacks = this.baseUrl + "skus/add-pack-config";
  // editPack = this.baseUrl + "skus/edit-pack-config";

  // // User
  // uploadExcelsheet = this.baseUrl + "registration/insertuser";
  // getUsersdetail = this.baseUrl + 'registration/readuser';
  // serchByname = this.baseUrl + 'registration/readuser?search_by=';

  // //Assign Coupons
  // assignCoupons = this.baseUrl + "skus/coupon-assignment";
  // couponRequest = this.baseUrl + "skus/generate-coupon-request";
  // generateCoupon = this.baseUrl + "coupon/genereatecoupons";
  // getRolls = this.baseUrl + 'user/get-roles';
  // passRollid = this.baseUrl + "registration/readuser?role_id="


}
export interface ApiConfigurationInterface { }





