import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { url } from 'inspector';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';
import { ToastService } from 'src/app/services/toast/toast.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  formGroup;
  registration: any;
  checked;
  profileDetails: any;
  image: any;
  terms;
  id;
  submitted = false;
  transaction_ref: any;
  constructor(
    private fb: FormBuilder,
    private toast: ToastClientService,
    private apiservice: ApiService,
    private apiConfig: ApiConfiguration,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,

  ) {

  }

  ngOnInit() {
    this.getParamsFromUrl();
    this.registrationForm();

  }

  getParamsFromUrl() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.transaction_ref = params['transaction_ref'];
      console.log(this.transaction_ref);

      this.getuserData();
    });
  }

  getuserData() {
    this.apiservice.get(this.apiConfig.getUserData + this.transaction_ref)
      .subscribe((res: any) => {
        console.log(res.data)
        if (res.status == 200) {
          this.profileDetails = res.data[0];
          if (res.data.length > 0) {
            this.registration = this.profileDetails.status;
            // this.profileDetails.outlet_wa_number = this.profileDetails.outlet_wa_number.slice(2)
            this.formGroup.controls["outlet_name"].patchValue(this.profileDetails.outlet_name)
            this.formGroup.controls["outlet_wa_number"].patchValue(this.profileDetails.outlet_wa_number)
            this.formGroup.controls["dhanush_id"].patchValue(this.profileDetails.dhanush_id)
            this.formGroup.controls["other_brand_count"].patchValue(this.profileDetails.other_brand_count)
            this.image = this.profileDetails.shop_image
          }
          else {
            // this.registration = 1;
          }
          console.log(this.profileDetails)
        }
      })
  }


  registrationForm() {
    this.formGroup = this.fb.group({
      outlet_name: new FormControl('', [Validators.required]),
      outlet_wa_number: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{12}$")]),
      dhanush_id: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9]*$")]),
      acceptTerms: ['', Validators.required],
      other_brand_count: ['', Validators.required],

    });
  }

  get f() {
    return this.formGroup.controls;
  }


  onSubmitSku(formData) {
    this.submitted = true;
    if (this.formGroup.invalid) {
      return
    }
    if (formData.acceptTerms == false) {
    } else {
      //console.log(formData);
      let sendFormData = {
        transaction_ref: this.transaction_ref,
        terms_accept: 1
      }
      this.apiservice.post(this.apiConfig.postUserData, sendFormData)
        .subscribe((res) => {
          if (res.status == 200) {
            this.submitted = false;
            this.registration = 1
            this.toast.Success(res.body.data.message);
            this.getuserData();
          }

        }), err => {
          console.log('err')
        }
    }


  }

  accept(e) {
    this.checked = e.target.checked;
  }


  // openWhatsApp() {
  //   window.open('https://web.whatsapp.com/');
  // }
  showModal(targetModal: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
  }


  openScrollableContent(longContent) {
    this.modalService.open(longContent, { scrollable: true });
  }

}

