import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './common/registration/registration.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'registration',
    pathMatch: 'full',
  },

  {
    path: 'registration',
    component: RegistrationComponent,
  },

   

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // useHash: true
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
